import {
  Alert,
  Autocomplete,
  Grid,
  Snackbar,
  TextField,
  Typography
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GenericForm from "../../../GenericForm";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import {
  TASK_END_DATE_FIELD_ID,
  TASK_START_DATE_FIELD_ID,
} from "../../edvenswa.emportal.timesheet/constants/Constants";
import { getTaskFields } from "../../edvenswa.emportal.timesheet/fields/GetFields";
import { TASK_PROJECTS_FIELD_ID } from "../constants/Constants";
import { utils, write } from "xlsx";
import saveAs from "file-saver";

const GenerateReport = (props) => {
  const { setIsDrawerOpen, snackbarOpen, setSnackbarOpen, errorMessage, user, tenantId } =
    props;
  const [formData, setFormData] = useState({});
  const [projects, setProjects] = useState([]);
  const [projectValue, setProjectValue] = useState(false);
  const [associates, setAssociates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tasks, setTasks] = useState([]);
  const [value, setValue] = useState("project");
  const [downloadType, setDownloadType] = useState();
  const fields = [
    TASK_START_DATE_FIELD_ID,
    TASK_END_DATE_FIELD_ID,
    TASK_PROJECTS_FIELD_ID,
  ];
  const formFields = getTaskFields(fields);
  const [errors, setErrors] = useState({});
  const [leaveCount, setLeaveCount] = useState(0);
  const role = user.roles[0];
  const navigate = useNavigate();

  useEffect(() => {
    if (projects.length === 0 && !projectValue) {
      if (role === "Admin") {
        axiosInstance.get(`/project/projectLists?sortBy=ACTIVE&tenantId=${tenantId}`).then((res) => {
          setProjects(res.data);
          console.log(res.data, "fgygfyrefuh");
          setProjectValue(true)
        });
      } else {
        setProjects(user?.projects);
        setProjectValue(true)
      }
    }
  }, [projects, associates, tasks]);



  const handleChange = (field_name, value) => {
    switch (field_name) {
      case TASK_START_DATE_FIELD_ID:
      case TASK_END_DATE_FIELD_ID: {
        const selectedStartDate =
          field_name === TASK_START_DATE_FIELD_ID
            ? value
            : formData[TASK_START_DATE_FIELD_ID];
        const selectedEndDate =
          field_name === TASK_END_DATE_FIELD_ID
            ? value
            : formData[TASK_END_DATE_FIELD_ID];
        const today = new Date();
        setStartDate(selectedStartDate);
        setEndDate(selectedEndDate);
        const oneYearBefore = new Date().setFullYear(today.getFullYear() - 1);

        if (new Date(selectedStartDate) < oneYearBefore) {
          setErrors({
            ...errors,
            [TASK_START_DATE_FIELD_ID]: {
              message: "Start date must be within one year from today.",
            },
          });
        } else if (
          selectedStartDate &&
          endDate &&
          (new Date(selectedStartDate) < oneYearBefore ||
            new Date(selectedEndDate) < oneYearBefore ||
            new Date(selectedEndDate) < new Date(selectedStartDate))
        ) {
          setErrors({
            ...errors,
            [TASK_END_DATE_FIELD_ID]: {
              message: "End date must be after start date.",
            },
          });
        } else {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[TASK_START_DATE_FIELD_ID];
            delete updatedErrors[TASK_END_DATE_FIELD_ID];
            return updatedErrors;
          });
        }
        setFormData({ ...formData, [field_name]: value });
        break;
      }

      case TASK_PROJECTS_FIELD_ID: {
        setFormData({ ...formData, [field_name]: value.title });
        break;
      }
      default: {
        console.error("Invalid field name: ", field_name);
      }
    }
  };

  const handleSave = (e) => {
    if (downloadType === "PDF") {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);

      axiosInstance.post("/task/report", formData).then((res) => {

        const countLeaves = () => {
          let count = 0;
          res.data.forEach(element => {
            if (element.taskCategory === "Leave") {
              const start = new Date(element.startDate[0], element.startDate[1], element.startDate[2]);
              const end = new Date(element.endDate[0], element.endDate[1], element.endDate[2]);
              count += (end - start) / (1000 * 60 * 60 * 24) + 1;
            }
          });
          return count;
        };

        const leaveCount = countLeaves();
        setLeaveCount(leaveCount);

        if (res.data.length > 0) {
          let estimatedHourssum = 0;
          let workedHourssum = 0;
          let percentageUsedsum = 0;

          res.data.forEach((task) => {
            estimatedHourssum += task.estimatedHours;
            workedHourssum += task.workedHours;
            percentageUsedsum += task.percentageUsed;
          });
          workedHourssum -= leaveCount * 8 * 3600;

          const pdfWidth = 210;
          const pdfHeight = 250;
          const pdf = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: [pdfWidth, pdfHeight],
          });
          const logoUrl = "https://tse3.mm.bing.net/th?id=OIP.xrnfPsu-nIEVf0HzuyWt3AAAAA&pid=Api&P=0&h=180";
          const centerX = pdf.internal.pageSize.width / 2;
          const imageWidth = 20;
          const imageHeight = 20;
          const imageY = 2;
          pdf.addImage(logoUrl, centerX - imageWidth / 2, imageY, imageWidth, imageHeight);

          // Sort data by fullname and startDate
          const sortedData = res.data.sort((a, b) => {
            const nameA = a.fullname ? a.fullname.toLowerCase() : "";
            const nameB = b.fullname ? b.fullname.toLowerCase() : "";

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            const dateA = new Date(a.startDate);
            const dateB = new Date(b.startDate);

            return dateA - dateB;
          });

          // Filter suspected users
          const isSuspectedUser = sortedData.filter(
            (task) => (task.estimatedHours / 3600) > 4
            // && task.associate !== user.associateId
          );

          // Select the data to format based on the value of "overtime"
          const dataToFormat = value === "overtime" ? isSuspectedUser : sortedData;

          // Format the data
          const formattedData = dataToFormat.reduce((acc, task) => {
            const taskData = JSON.parse(task?.task)?.workedDayHours !== null && Object.keys(JSON.parse(task?.task)?.workedDayHours).map((date) => {
              // const used = `${(task?.percentageUsed).toFixed(2)}`;
              const seconds = JSON.parse(task.task).workedDayHours[date];
              // const plannedHours = task?.estimatedHours ? `${Math.floor(task.estimatedHours / 3600)}:${Math.floor((task.estimatedHours % 3600) / 60).toString().padStart(2, '0')}` : "0:00";
              // const workedHours = task?.workedHours ? `${Math.floor(task.workedHours / 3600)}:${Math.floor((task.workedHours % 3600) / 60).toString().padStart(2, '0')}` : "0:00";
              return {
                "Associate": task.fullname ? task.fullname : "",
                "Task": task.taskName ? task.taskName : "",
                "Task Start Date": task.startDate ? moment(task.startDate, "YYYY,MM,DD").format("DD-MMM-yyyy") : " ",
                "Task End Date": task.endDate ? moment(task.endDate, "YYYY,MM,DD").format("DD-MMM-yyyy") : " ",
                "Task Category": task.category ? task.category : "",
                "Date Logged": moment(date, "DD-MM-YYYY").format("DD-MMM-YYYY"),
                "Hours Logged": moment().startOf('day').seconds(seconds).format('HH:mm'),
                // "Planned Hours": plannedHours,
                // "Worked Hours": workedHours,
                // "% used": `${used}`,
              };
            });
            return acc.concat(taskData);
          }, []);

          // Ensure that empty rows are not included
          const cleanedFormattedData = formattedData.filter(row => Object.values(row).some(val => val));

          // Sort formattedData by Associate and Date Logged
          const sortedFormattedData = cleanedFormattedData.sort((a, b) => {
            const nameA = a["Associate"] ? a["Associate"].toLowerCase() : "";
            const nameB = b["Associate"] ? b["Associate"].toLowerCase() : "";

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            const dateA = moment(a["Date Logged"], 'DD-MMM-yyyy').toDate();
            const dateB = moment(b["Date Logged"], 'DD-MMM-yyyy').toDate();

            return dateA - dateB;
          });
          // Prepare the final header and userData
          const header = [
            "Associate",
            "Task",
            "Task Start Date",
            "Task End Date",
            "Task Category",
            "Date Logged",
            "Hours Logged",
            // "Planned Hours",
            // "Worked Hours",
            // "% used",
          ];

          const userData = sortedFormattedData.filter(val =>
            moment(val["Date Logged"], 'DD-MMM-YYYY').isSameOrAfter(moment(startDate)) && moment(val["Date Logged"], 'DD-MMM-YYYY').isSameOrBefore(moment(endDate)), startDate, endDate
          ).map((val) => header.map((header) => val[header]))

          let totalLoggedSeconds = sortedFormattedData.reduce((acc, val) => {
            if (
              moment(val["Date Logged"], 'DD-MMM-YYYY').isSameOrAfter(moment(startDate)) &&
              moment(val["Date Logged"], 'DD-MMM-YYYY').isSameOrBefore(moment(endDate))
            ) {
              const hoursLogged = val["Hours Logged"];
              // Parse hoursLogged correctly assuming format 'HH:mm'
              const seconds = moment.duration(hoursLogged).asSeconds();
              return acc + seconds;
            }
            return acc;
          }, 0);

          console.log(sortedFormattedData);

          // Calculate the total leave hours in seconds
          const leaveHoursLogged = sortedFormattedData
            .filter(val => val["Task Category"] === "Leave")
            .map(val => moment.duration(val["Hours Logged"], 'HH:mm').asSeconds());

          const totalLeaveSeconds = leaveHoursLogged.reduce((acc, seconds) => acc + seconds, 0);
          const totalLeaveHours = `${Math.floor(totalLeaveSeconds / 3600)}:${Math.floor((totalLeaveSeconds % 3600) / 60).toString().padStart(2, '0')}`;
          console.log(totalLeaveHours);

          //remove totalLeaveSeconds from totalLoggedSeconds
          totalLoggedSeconds = Math.max(0, totalLoggedSeconds - totalLeaveSeconds);

          //converting toatlLoggedSeconds into Hours
          const totalLoggedHours = `${Math.floor(totalLoggedSeconds / 3600)}:${Math.floor((totalLoggedSeconds % 3600) / 60).toString().padStart(2, '0')}`;
          console.log(totalLoggedHours);

          const tableStartY = imageY + imageHeight + 20; //44

          pdf.autoTable({
            head: [header],
            body: userData,
            margin: { left: 8, right: 8, bottom: 10 },
            startY: startDate && endDate ? 58 : 50,
            rowPageBreak: 'avoid',
            headStyles: {
              fillColor: "#008080",
              textColor: "#ffffff",
              fontStyle: "bold",
            },
            didDrawPage: (data) => {
              pdf.setFont("Times New Roman, Times, serif");
              const [hours, minutes] = totalLoggedHours.split(':').map(Number);
              const totalLoggedSeconds = hours * 3600 + minutes * 60;
              const formattedPercentage = `${((totalLoggedSeconds / estimatedHourssum) * 100).toFixed(2)} %`;
              if (value === "overtime") {
                if (data.pageNumber === 1) {
                  pdf.text(
                    `Dear Admin, Following is the Overtime Analysis Report of ${res.data[0].project} Project`,
                    12,
                    tableStartY - 6
                  );
                  pdf.setFontSize(13);
                  pdf.text(
                    startDate && endDate
                      ? `For the time period of ${moment(startDate).format("DD-MMM-yyyy")} to ${moment(endDate).format("DD-MMM-yyyy")} `
                      : ``,
                    12,
                    46
                  );
                  pdf.text((`Total Task Planned Hours- ${Math.floor(estimatedHourssum / 3600)}:${Math.floor((estimatedHourssum % 3600) / 60).toString().padStart(2, '0')},  Total Hours Logged- ${totalLoggedHours}, Total Leave Hours- ${totalLeaveHours}, Avg. Utilization- ${formattedPercentage}`), 12, startDate && endDate ? 54 : 46);
                }
              } else if (value === "project") {
                const [hours, minutes] = totalLoggedHours.split(':').map(Number);
                const totalLoggedSeconds = hours * 3600 + minutes * 60;
                const formattedPercentage = `${((totalLoggedSeconds / estimatedHourssum) * 100).toFixed(2)} %`;

                const reportTitle = value === "project" ? res.data[0].project : `${res.data[0].fullname}`;
                if (data.pageNumber === 1) {
                  pdf.text(
                    `Dear Admin, Following is the report of ${reportTitle} Project`,
                    12,
                    tableStartY - 6
                  );
                  pdf.setFontSize(13);
                  pdf.text(
                    startDate && endDate
                      ? `For the time period of ${moment(startDate).format("DD-MMM-yyyy")} to ${moment(endDate).format("DD-MMM-yyyy")} `
                      : ``,
                    12,
                    46
                  );
                  pdf.text((`Total Task Planned Hours- ${Math.floor(estimatedHourssum / 3600)}:${Math.floor((estimatedHourssum % 3600) / 60).toString().padStart(2, '0')},  Total Hours Logged- ${totalLoggedHours}, Total Leave Hours- ${totalLeaveHours},  Avg. Utilization- ${formattedPercentage}`), 12, startDate && endDate ? 54 : 46);
                }
              }
            },
            didParseCell: function (data) {
              data.cell.styles.lineWidth = 0.1; // Set border width
              data.cell.styles.lineColor = [0, 0, 0]; // Set border color to black
              if (data.column.index === 5 || data.column.index === 6) {
                data.cell.styles.fontStyle = "bold"; // Set font style to bold
              }
            },
          });

          // Check if content exceeds current page and add new page if needed
          if (pdf.autoTable.previous.finalY > pdf.internal.pageSize.height - 20) {
            pdf.addPage();
          }

          if (value === "project") {
            pdf.save(`${res.data[0].project}.pdf`);
          } else if (value === "overtime") {
            pdf.save(`Overtime Analysis Report of ${res.data[0].project}.pdf`);
          } else {
            pdf.save("AllAssociateProjectReport.pdf");
          }

          setLoading(false);
        } else {
          setLoading(false);
          setIsDrawerOpen(false);
          props?.onError({ response: { data: "No Tasks found for this Project" } });
        }
      });
    } else {
      axiosInstance.post("/task/report", formData).then((res) => {

        if (res.data.length > 0) {
          const projectReport = `Project Report of ${res.data[0].project}.xlsx`;
          const overtimeReport = `Overtime Analysis Report of ${res.data[0].project}.xlsx`;
          const fileName = value === "overtime" ? overtimeReport : projectReport;
          const sortedData = res.data.sort((a, b) => {
            const nameA = a.fullname ? a.fullname.toLowerCase() : "";
            const nameB = b.fullname ? b.fullname.toLowerCase() : "";

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            const dateA = new Date(a.startDate);
            const dateB = new Date(b.startDate);

            return dateA - dateB;
          });
          // Filter suspected users
          const isSuspectedUser = sortedData.filter(
            (task) => (task.estimatedHours / 3600) > 4
          );

          // Select the data to format based on the value of "overtime"
          const dataToFormat = value === "overtime" ? isSuspectedUser : sortedData;

          const formattedData = dataToFormat.reduce((acc, task) => {
            const taskData = JSON.parse(task?.task)?.workedDayHours !== null && Object.keys(JSON.parse(task?.task).workedDayHours).map((date) => {
              const seconds = JSON.parse(task.task).workedDayHours[date];
              const plannedHours = task?.estimatedHours ? `${Math.floor(task?.estimatedHours / 3600)}:${Math.floor((task?.estimatedHours % 3600) / 60).toString().padStart(2, '0')}` : "0:00";
              const workedHours = task?.workedHours ? `${Math.floor(task?.workedHours / 3600)}:${Math.floor((task?.workedHours % 3600) / 60).toString().padStart(2, '0')}` : "0:00";

              return {
                "Associate": task.fullname ? task.fullname : "",
                "Task": task.taskName ? task.taskName : "",
                "Task Category": task.category ? task.category : "",
                "Task Start Date": task.startDate ? moment(task.startDate, "YYYY,MM,DD").format("DD-MMM-yyyy") : " ",
                "Task End Date": task.endDate ? moment(task.endDate, "YYYY,MM,DD").format("DD-MMM-yyyy") : " ",
                "Date Logged": moment(date, "DD-MM-YYYY").format("DD-MMM-YYYY"),
                "Hours Logged": moment().startOf('day').seconds(seconds).format('HH:mm'),
                "Planned Hours": plannedHours,
                "Worked Hours": workedHours,
              };
            });
            return acc.concat(taskData);
          }, []);

          const cleanedFormattedData = formattedData.filter(row => Object.values(row).some(val => val));

          // Sort formattedData by Associate and Date Logged
          const sortedFormattedData = cleanedFormattedData.sort((a, b) => {
            const nameA = a["Associate"] ? a["Associate"].toLowerCase() : "";
            const nameB = b["Associate"] ? b["Associate"].toLowerCase() : "";

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            const dateA = moment(a["Date Logged"], 'DD-MMM-yyyy').toDate();
            const dateB = moment(b["Date Logged"], 'DD-MMM-yyyy').toDate();

            return dateA - dateB;
          });

          const header = [
            "Associate",
            "Task",
            "Task Category",
            "Task Start Date",
            "Task End Date",
            "Date Logged",
            "Hours Logged",
            "Planned Hours",
            "Worked Hours",
          ];

          const userData = sortedFormattedData.filter(val =>
            moment(val["Date Logged"], 'DD-MMM-YYYY').isSameOrAfter(moment(startDate)) && moment(val["Date Logged"], 'DD-MMM-YYYY').isSameOrBefore(moment(endDate)), startDate, endDate
          );

          // Calculate total logged hours
          let totalLoggedSeconds = sortedFormattedData.reduce((acc, val) => {
            if (
              moment(val["Date Logged"], 'DD-MMM-YYYY').isSameOrAfter(moment(startDate)) &&
              moment(val["Date Logged"], 'DD-MMM-YYYY').isSameOrBefore(moment(endDate))
            ) {
              const [hours, minutes] = val["Hours Logged"].split(":").map(Number);
              const hoursLogged = (hours * 3600) + (minutes * 60);
              return acc + hoursLogged;
            }
            return acc;
          }, 0);

          // Calculate the total leave hours in seconds
          const leaveHoursLogged = sortedFormattedData
            .filter(val => val["Task Category"] === "Leave")
            .map(val => moment.duration(val["Hours Logged"]).asSeconds());

          const totalLeaveSeconds = leaveHoursLogged.reduce((acc, seconds) => acc + seconds, 0);
          const totalLeaveHours = `${Math.floor(totalLeaveSeconds / 3600)}:${Math.floor((totalLeaveSeconds % 3600) / 60).toString().padStart(2, '0')}`;

          // Convert total seconds back to HH:mm format
          const totalLoggedHours = `${Math.floor(totalLoggedSeconds / 3600)}:${Math.floor((totalLoggedSeconds % 3600) / 60).toString().padStart(2, '0')}`;

          // Convert totalLoggedHours from HH:mm to seconds
          const [loggedHours, loggedMinutes] = totalLoggedHours.split(":").map(Number);
          const totalLoggedSecondsWithoutLeave = (loggedHours * 3600) + (loggedMinutes * 60) - totalLeaveSeconds;

          // Convert the resulting seconds back into HH:mm format
          const totalLoggedHoursWithoutLeaves = `${Math.floor(totalLoggedSecondsWithoutLeave / 3600)}:${Math.floor((totalLoggedSecondsWithoutLeave % 3600) / 60).toString().padStart(2, '0')}`;

          console.log(totalLoggedHoursWithoutLeaves);

          //Insert an empty row to create a gap before the summary row
          userData.push({
            "Associate": "",
            "Task":"",
            "Task Category":"",
            "Task Start Date":"",
            "Task End Date":"",
            "Date Logged":"",
            "Hours Logged":"",
            "Planned Hours":"",
            "Worked Hours":"",
          });

          // Add total logged hours as three separate summary rows
          const summaryRow1 = {
            "Associate": "",
            "Task":"",
            "Task Category":"",
            "Task Start Date":"",
            "Task End Date":"",
            "Date Logged":"",
            "Hours Logged":`Total Logged Hours: ${totalLoggedHoursWithoutLeaves}`,
            "Planned Hours":"",
            "Worked Hours":"",
          };

          const summaryRow2 = {
            "Associate": "",
            "Task":"",
            "Task Category":"",
            "Task Start Date":"",
            "Task End Date":"",
            "Date Logged":"",
            "Hours Logged":`Total Leave Hours: ${totalLeaveHours}`,
            "Planned Hours":"",
            "Worked Hours":"",
          };

          userData.push(summaryRow1,summaryRow2)

          const worksheet = utils.json_to_sheet(userData, { header });
          const workbook = {
            Sheets: { data: worksheet },
            SheetNames: ["data"],
          };
          const excelBuffer = write(workbook, {
            bookType: "xlsx",
            type: "array",
          });
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const data = new Blob([excelBuffer], { type: fileType });
          saveAs(data, fileName);
          setLoading(false);
        }
      })
    }

  };

  const handleAllSaveReport = (e) => {
    e.preventDefault();
    e.stopPropagation();
    axiosInstance.put("/task/all/report/duration", formData).then((res) => {
      navigate("/home/reports/view", { state: { data: res.data } });
    });
  };


  const renderCustomInput = (field, key) => {
    if (field && field.type !== "radio") {
      return (
        <>
          {
            field.select && field.id === TASK_PROJECTS_FIELD_ID ? (
              <Autocomplete
                size="small"
                sx={{
                  display: "inline-block",
                  "& .MuiAutocomplete-inputRoot": {
                    fontSize: "0.75rem",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  },
                  "& .MuiAutocomplete-input": {
                    paddingTop: "0",
                  },
                  "& .MuiInputBase-root": {
                    minWidth: "0",
                  },
                  "& .MuiIconButton-root": {
                    padding: "4px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.75rem",
                  },
                }}
                fullWidth
                onChange={(event, newValue) => {
                  if (newValue != null) {
                    handleChange(field.name, newValue);
                  }
                }}
                // defaultValue={role === "Project Manager" ? user.projects : ""}
                // disabled={role === "Project Manager" ? true : false}
                InputLabelProps={{ shrink: true, style: { fontSize: "0.75rem" } }}
                InputProps={{ style: { font: "small-caption" } }}
                options={projects}
                getOptionLabel={(option) => option.title}
                renderOption={(props, option) => (
                  <li {...props} style={{ fontFamily: "Lucida Grande" }}>
                    {option.title}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Projects" required InputProps={{
                    ...params.InputProps,
                    style: { fontFamily: 'Lucida Grande' },
                  }}
                    InputLabelProps={{
                      style: { fontFamily: 'Lucida Grande' },
                    }} />
                )}
                noOptionsText={"No projects with the given name"}
              />)
              : (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  key={key}
                  InputProps={{ style: { font: "small-caption", fontFamily: "Lucida Grande" } }}
                  InputLabelProps={{ style: { font: "small-caption", fontFamily: "Lucida Grande" }, shrink: true }}
                  label={field.label}
                  error={errors[field.name] ? true : false}
                  helperText={errors?.[field.name]?.message}
                  defaultValue={
                    formData && formData[field.name] ? formData[field.name] : ""
                  }
                  onChange={(event) => handleChange(field.name, event.target.value)}
                >
                  : (
                  <React.Fragment></React.Fragment>
                  )
                </TextField>
              )}
        </>
      );
    } else {
      return <React.Fragment />;
    }
  };

  return (
    <>
      <Grid mt={4} marginLeft={3} sx={{ justifyContent: "center" }}>
        <Typography>Select Period</Typography>
      </Grid>
      <GenericForm
        value={value}
        setValue={setValue}
        type={props?.type}
        formData={formData}
        setDownloadType={setDownloadType}
        formFields={formFields}
        errors={errors}
        handleChange={handleChange}
        setIsDrawerOpen={setIsDrawerOpen}
        handleSubmit={value === "all" ? handleAllSaveReport : handleSave}
        renderCustomInput={renderCustomInput}
        loading={loading}
        setLoading={setLoading}
      />
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(!snackbarOpen)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert severity="warning">{errorMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default GenerateReport;
