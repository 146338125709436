import React from 'react'
import ProjectManagerAppraisal from './ProjectManagerAppraisal'
import UserScreenAppraisal from './UserScreenAppraisal';
import AdminScreenAppraisal from './AdminScreenAppraisal';

const ShowUsers = (props) => {
    console.log(props);

    return (
        props.user.roles[0] === "Project Manager" ?
            <ProjectManagerAppraisal {...props} />
            : props.user.roles[0] === "User" ? <UserScreenAppraisal {...props} /> : <AdminScreenAppraisal {...props} />
    )
}

export default ShowUsers
